import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ConversionRateProcedure from "../components/templates/ConversionRateProcedure"

const conversionRateData = [
  {doctor: 'Dr. Green', total: 25, value: 11, percentage: 44},
  {doctor: 'Dr. Jones', total: 50, value: 18, percentage: 36},
  {doctor: 'Dr. Smith', total: 25, value: 21, percentage: 84},
];

const chart = {
  totalWidth: 1050,
  totalHeight: 400,
  conversionRateData: conversionRateData,
  labelKey: 'doctor',
  color: '#bbc3c1',
  totalColor: '#7cb442',
}

const ConversionRateProcedurePage = () => (

  <Layout>
    <SEO title="Conversion Rate by Procedure" />
    <div><Link to="/">Go back to the homepage</Link></div>
    <ConversionRateProcedure
      practice='ABC Animal Hospital'
      date='March 2020'
      type='Canine'
      chart={chart}
    />
  </Layout>
)

export default ConversionRateProcedurePage
