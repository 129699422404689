import React, { useEffect } from "react"

import {
  createSVG,
  drawYAxis,
  drawXAxis,
  drawBars
} from "./HorizontalBarChart.utilities"


const HorizontalBarChart = (
  {
    width,
    height,
    margin,
    data,
    labelKey,
    color,
    totalColor
  }) => {
  useEffect(() => {
    const svg = createSVG({margin, width, height});
    const x = drawXAxis({svg, data, width, height});
    const y = drawYAxis({svg, labelKey, data, totalColor, height});

    drawBars({svg, labelKey, data, color, totalColor, x, y})
  });

  return (
    <svg />
  )
}

export default HorizontalBarChart;
