import * as d3 from "d3";

export const createSVG = ({margin, width, height}) => {
  // clean svg
  d3.selectAll("svg > *").remove();

  return d3.select("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform",
      "translate(" + margin.left + "," + margin.top + ")");
};

export const drawYAxis = ({svg, labelKey, data, totalColor, height}) => {
  let barLabels = data.map(metric => `${metric[labelKey]} (${metric['percentage']}/100) `);
  let y = d3.scaleBand().domain(barLabels).range([0, height]).padding(.5);
  svg
    .append("g")
    .call(d3.axisLeft(y).tickSize(0))
    .selectAll(".tick text")
    .each(function(d, i){
      d3.select(this)
        .attr("transform", "translate(-20, -5)")
        .call(wrap, 60);
      if (i === 0) {
        d3.select(this)
          .attr("color", totalColor)
          .attr("font-weight", "bold")
      }
    });
  return y;
};

function wrap(text, width) {
  text.each(function() {
    var text = d3.select(this),
      words = text.text().split(/\s+/).reverse(),
      word,
      line = [],
      lineNumber = 0,
      lineHeight = 1.1, // ems
      y = text.attr("y"),
      dy = parseFloat(text.attr("dy")),
      tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em")
    while (word = words.pop()) {
      line.push(word)
      tspan.text(line.join(" "))
      if (tspan.node().getComputedTextLength() > width) {
        line.pop()
        tspan.text(line.join(" "))
        line = [word]
        tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", `${++lineNumber * lineHeight + dy}em`).text(word)
      }
    }
  })
}

export const drawXAxis = ({svg, width, height}) => {
  const x = d3.scaleLinear().domain([0, 100]).range([0, width]);
  svg
    .append("g")
    .attr("transform", "translate(0," + height + ")")
    .call(d3.axisBottom(x)
            .ticks(10)
            .tickSize(-height)
            .tickFormat(d => d + '%'))
    .selectAll("line").style("stroke-opacity", "0.2");

  return x;
};

export const drawBars = ({svg, labelKey, data, color, totalColor, x, y}) => {
  // draw bars
  let bars = svg.selectAll('.bar')
    .data(data)
    .enter()
    .append("rect");

  bars
    .attr("y", (d) => y(`${d[labelKey]} (${d['percentage']}/100) `))
    .attr("height", y.bandwidth())
    .attr("x", 0)
    .attr("width", (d) =>  x(d.percentage))
    .attr("fill", (d, i) => { return (i === 0) ? totalColor: color})

};


