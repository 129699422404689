import React from "react"
import styled from "styled-components"

import fonts from "../../fonts/fonts.css"

import ComplianceTrackerSelect from "../molecules/ComplianceTrackerSelect"
import QuestionMarkIcon from "../atoms/QuestionMarkIcon/QuestionMarkIcon"

import vsLogo from "../../images/VS_horizontal_BLACK.png"
import TotalHorizontalBarChart from "../organisms/HorizontalBarChart/TotalHorizontalBarChart"

const ConversionRateProcedure = ({chart, practice, date, type}) => {
  const ageSelectItems = [
    `All ${type}s`,
    `${type === 'Canine' ? 'Puppies' : 'Kittens'} < 1 year`,
    'Adult 1-7 years',
    'Senior 8+ years'
  ];

  const procedureCanineSelectItems = [
    `Rabies Vaccination (${50} total this month)`,
    `DHPP Vaccination (${50} total this month)`,
    `Leptospirosis Vaccination (${50} total this month)`,
    `Bordetella Vaccination (${50} total this month)`,
    `Lyme Vaccination (${50} total this month)`,
    `Tick Pathogen Test (${50} total this month)`,
    `Heartworm Test (${50} total this month)`,
    `Fecal Test (${50} total this month)`,
    `Wellness Bloodwork (${50} total this month)`,
    `Dental Prophylaxis (${50} total this month)`,
    `Internal Parasite Coverage (${50} total this month)`,
    `External Parasite Coverage (${50} total this month)`
  ];

  const procedureFelineSelectItems = [
    `Rabies Vaccination (${50} total this month)`,
    `FVRCP Vaccination (${50} total this month)`,
    `Feline Leukemia Vaccination (${50} total this month)`,
    `Fecal Test (${50} total this month)`,
    `Wellness Bloodwork (${50} total this month)`,
    `Dental Prophylaxis (${50} total this month)`,
    `Internal Parasite Coverage (${50} total this month)`,
    `External Parasite Coverage (${50} total this month)`
  ];

  return (
    <ConversionRateProcedureContainer>
      <Header>
        <div>{practice}</div>
        <div>{date}</div>
        <Logo src={vsLogo}/>
      </Header>
      <Description>
        <Title>
          <div>Conversion Rate by Procedure – {type}</div>
          <QuestionMarkIcon/>
        </Title>
        <div>
          How successful was each doctor at converting non-compliant patients into compliant patients for a given wellness procedure?
        </div>
      </Description>
      <SelectContainer>
        <ComplianceTrackerSelect items={ageSelectItems}/>
        <ComplianceTrackerSelect items={type === 'Canine' ? procedureCanineSelectItems : procedureFelineSelectItems}/>
      </SelectContainer>
      <ChartContainer>
        <TotalHorizontalBarChart
          totalWidth={chart.totalWidth}
          totalHeight={chart.totalHeight}
          conversionRateData={chart.conversionRateData}
          labelKey={chart.labelKey}
          color={chart.color}
          totalColor={chart.totalColor}
        />
      </ChartContainer>
    </ConversionRateProcedureContainer>
  )
}

export default ConversionRateProcedure;

const ConversionRateProcedureContainer = styled.div`
  max-width: 1170px;
  margin: auto;
  padding 0 15px;
  font-family: HelveticaNeueLTStd, sans-serif;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 15px 0;
  padding-bottom: 3px;
  border-bottom: solid 0.5pt #3C3B3B;
  font-size: 10pt;
  font-weight: 300;
`

const Logo = styled.img`
  width: 80px;
`

const Description = styled.div`
  margin: 0 15px 20px;
  padding: 20px 0 40px;
  border-bottom: solid 6pt #B2B4B7;
  font-size: 12pt;
  font-weight: 300;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  font-size: 25pt;
  font-weight: bold;
  color: #8EC641;
`

const SelectContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 15px 50px 0;
`

const ChartContainer = styled.div`
  text-align: right;
`
