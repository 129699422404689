import React from "react"
import styled from "styled-components"

import HorizontalBarChart from "./HorizontalBarChart"

const TotalHorizontalBarChart = (
  {
    totalWidth,
    totalHeight,
    conversionRateData,
    labelKey,
    color,
    totalColor
  }) => {
  // set the dimensions and margins of the benchmark graph
  const margin = { top: 20, right: 20, bottom: 40, left: 80 };
  const width = totalWidth - margin.left - margin.right;
  const height = totalHeight - margin.top - margin.bottom;

  const total = conversionRateData.reduce((prev,cur) => {return prev + cur.total}, 0);
  const value = conversionRateData.reduce((prev,cur) => {return prev + cur.value}, 0);
  const percentage = value/total * 100;
  const dataWithTotal = [{percentage: percentage, doctor: 'Practice', total: total, value: value}].concat(conversionRateData);
  return (
    <Container>
      <HorizontalBarChart
        width={width}
        height={height}
        margin={margin}
        data={dataWithTotal}
        labelKey={labelKey}
        color={color}
        totalColor={totalColor}
      />
    </Container>
  )
}

export default TotalHorizontalBarChart;

const Container = styled.div`
  display: inline-block;
  padding-top: 20px;
`
